<style lang="scss" scoped></style>

<template>
    <div class="page-openlock">
        <div class="toolbars mrgb5">
            <div class="buttons mrgb5">
                <div class="fl">
                    <el-tooltip content="如果发现数据不正确，请重新生成后再查看!" placement="top-start">
                        <el-button class="mrgb5" type="primary" size="medium" @click="_spiderLogs()"><i class="fa fa-refresh"></i> 更新开锁日志</el-button>
                    </el-tooltip>
                </div>
                <div class="fr">
                    <el-date-picker class="c-datetime-range mrgr5 mrgb5" v-model="selectedTime" type="datetimerange" size="medium"
                        :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']" :clearable="false"
                        range-separator="至"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        @change="_search()"
                        align="right">
                    </el-date-picker>
                    <el-input class="c-el-input mrgr5 mrgb5" placeholder="姓名/手机号查询" clearable v-model="listQuery.keywords" size="medium"></el-input>
                    <el-button class="mrgb5" type="primary" size="medium" @click="_search()"><i class="fa fa-search"></i> 查询</el-button>
                </div>
                <div class="clearb"></div>
            </div>
        </div>

        <div>
            <el-table :data="tableData" style="width: 100%" border size="small">
                <el-table-column label="姓名" prop="name">
                    <template slot-scope="scope">
                        <el-link @click.native="_query(scope.row, 'name')" v-if="scope.row.name">{{ scope.row.name }}</el-link>
                    </template>
                </el-table-column>
                <el-table-column label="手机号" prop="phone">
                    <template slot-scope="scope">
                        <el-link @click.native="_query(scope.row, 'phone')" v-if="scope.row.phone">{{ scope.row.phone }}</el-link>
                    </template>
                </el-table-column>
                <el-table-column label="开锁次数" prop="count"></el-table-column>
                <el-table-column label="操作" align="center" width="100" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="primary" plain size="mini" @click="_query(scope.row)">日志</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!--车辆日志-->
        <dialog-logs :ref="refDialogLogs"></dialog-logs>
    </div>
</template>

<script>
import configMixins from "@/mixins/config"
import * as funReport from "@/api/report"
import * as funSpider from "@/api/spider"
import moment from "moment"
import DialogLogs from "./components/dialogLogs"
export default {
    name: "pageOpenlock",
    mixins: [configMixins],
    components: { DialogLogs },
    data() {
        let that = this
        return {
            refDialogLogs: "refOpenlockToDialogLogs",
            tableData: [],
            listQuery: {
                area: "",
                beginTime: "",
                endTime: "",
                keywords: ""
            },
            selectedTime: [moment().subtract(1, "d").format("YYYY-MM-DD 00:00:00"), moment().subtract(1, "d").format("YYYY-MM-DD 23:59:59")],
            pickerOptions: {
                shortcuts: [
                    {
                        text: "昨天",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "days")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "本周",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "weeks", true)
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "本月",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "months", true)
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "上月",
                        onClick: (picker) => {
                            let startDate = moment().subtract(1, "months")
                            let timeArr = [startDate.format("YYYY-MM-01 00:00:00"), startDate.endOf("month").format("YYYY-MM-DD 23:59:59")]
                            picker.$emit('pick', timeArr)
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一周",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "weeks")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "months")
                            that.setQueryTime(timeArr)
                        }
                    }
                    /* ,
                    {
                        text: "近两月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 2, "months")
                            that.setQueryTime(timeArr)
                        }
                    } */
                ]
            }
        }
    },
    watch: {
        "$store.getters.serviceArea"(v) {
            this.mxIsSameActiveTabWithRoute(() => {
                this._search()
            })
        }
    },
    mounted() {
        this.getDataList()
    },
    methods: {
        // 重写本页的时间范围的快捷筛选，设置时间
        setPickerTime(picker, spaceTime, dateStr, isStartOf) {
            let timeArr = []
            let startTime = null
            let endTime = null
            if (isStartOf) {
                startTime = moment().startOf(dateStr).format("YYYY-MM-DD 00:00:00")
                endTime = moment().format("YYYY-MM-DD 23:59:59")
            } else {
                startTime = moment().subtract(spaceTime, dateStr).format("YYYY-MM-DD 00:00:00")
                endTime = moment().subtract(1, "d").format("YYYY-MM-DD 23:59:59")
            }
            timeArr = [startTime, endTime]
            if (picker) {
                picker.$emit('pick', timeArr);
            }
            return timeArr
        },
        setQueryTime(timeArr) {
            this.listQuery.beginTime = timeArr[0] || ""
            this.listQuery.endTime = timeArr[1] || ""
        },
        setSelectedTime() {
            let psTime = ""
            let peTime = ""
            if (this.selectedTime && this.selectedTime[0] && moment(this.selectedTime[0]).isValid()) {
                psTime = moment(this.selectedTime[0]).format("YYYY-MM-DD HH:mm:ss")
            }
            if (this.selectedTime && this.selectedTime[1] && moment(this.selectedTime[1]).isValid()) {
                peTime = moment(this.selectedTime[1]).format("YYYY-MM-DD HH:mm:ss")
            }
            this.listQuery.beginTime = psTime
            this.listQuery.endTime = peTime
            this.selectedTime = [this.listQuery.beginTime, this.listQuery.endTime]
        },
        getDataList() {
            this.setSelectedTime()
            if (!this.listQuery.beginTime || !this.listQuery.endTime) {
                this.warningMsg("请选择查询时间段!")
                return false
            }
            let daysDiff = moment(this.listQuery.endTime).diff(this.listQuery.beginTime, "days")
            if (daysDiff > 30) {
                this.warningMsg("查询时间不能超过31天!")
                return false
            }
            window.$common.fullLoading()
            this.listQuery.area = this.$store.getters.serviceArea || ""
            this.listQuery.keywords = this.listQuery.keywords.trim()
            funReport.GetUnlockStatistics(this.listQuery).then(response => {
                window.$common.closeFullLoading()
                this.tableData = []
                if (response && response.length > 0) {
                    this.tableData = response
                }
            })
        },
        _query(row, key) {
            let temp = {}
            temp.eventType = "23001"
            temp.keywords = key ? row[key] : row.name
            temp.startDate = this.listQuery.beginTime
            temp.endDate = this.listQuery.endTime
            this.$refs[this.refDialogLogs].open({ logsItem: temp })
        },
        _spiderLogs() {
            let that = this
            let curType = ""
            for (let i in this.vehicleLogsSpiderTypes) {
                let eType = this.vehicleLogsSpiderTypes[i]["23001"]
                if (eType) {
                    curType = eType
                    break
                }
            }
            let params = {
                type: curType || "",
                beginDate: this.listQuery.beginTime,
                endDate: this.listQuery.endTime,
                carId: "",
                pageSize: 50
            }
            if (!params.beginDate || !params.endDate) {
                this.alert("请选择时间段！")
                return false
            }
            this.confirm("执行更新后，请稍等2分钟左右，重新查询数据！").then(() => {
                funSpider.execVehicleLogSpider(params).then(() => {
                    that.successMsg("日志数据正在更新，请稍后查询！", {
                        onClose: () => {
                            that._search()
                        }
                    })
                })
            })
        },
        _search() {
            this.getDataList()
        }
    }
}
</script>